<script setup lang="ts">
import delve from 'dlv'
import { findPage } from '~/utils/findPage'

defineProps({
  title: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
  advisorText: {
    type: String,
  },
  hasAdvisor: {
    type: Boolean,
    default: true,
  },
})

const { data: employee } = await useAsyncData(
  'employees',
  () => findPage('employees', 'fr', '', {
    'pagination[pageSize]': 1,
  },
  ),
)
</script>

<template>
  <div class="message-container">
    <div class="icon">
      <slot name="icon" />
    </div>
    <h2>
      {{ title }}
    </h2>
    <p
      v-html="text"
    />
    <div
      v-if="hasAdvisor"
      class="advisor"
    >
      <NuxtImg
        width="60"
        :src="getStrapiMedia(employee.picture.data.attributes.url)"
        :alt="employee.picture.data.attributes.alternativeText"
      />
      <div v-if="!advisorText">
        <p>
          {{ $t('message.text') }}
        </p>
        <p>
          {{ $t('message.contactPhone') }}
        </p>
      </div>
      <div v-else>
        <p
          v-html="advisorText"
        />
      </div>
    </div>
    <slot name="button" />
  </div>
</template>

<style lang="scss">
  .message-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    &.success {
      .icon {
        color: var( --color-green );
      }
    }
    .icon {
      font-size: 6rem;

      .error {
        color: var(--color-red)
      }
    }

    h2 {
      font-size: 1.5rem;
      text-transform: none;
      font-weight: 500 ;
    }

    > p {
      text-align: center;
    }

    .advisor {
      display: inline-flex;
      align-items: center;
      background-color: var(--color-navy-0);
      border: 1px solid var(--color-navy-10);
      padding: 1rem;
      gap: 1rem;
      box-shadow: 0 1px 3px 0 #2F2B431A;
      margin: 2rem 0;

      img {
        width: 3rem;
        border-radius: 50px;
      }

      div {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
      }

      p {
        margin: 0;
        line-height: 1.5rem;

        @media (min-width: map-get($grid-breakpoints, lg)) {
          max-width: 300px;
        }

        .time {
          margin-top: 0.5rem;
          display: block;
        }
      }
    }
  }
</style>
