<script setup lang="ts">
import type { PropType } from 'vue'

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
  },
  uniqueId: {
    type: String,
  },
  type: {
    type: String as PropType<'text' | 'email' | 'tel' | 'password' | 'url' | 'search'>,
    default: 'text',
  },
  placeHolder: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
    required: true,
  },
  required: {
    type: Boolean,
  },
  hasLabel: {
    type: Boolean,
    default: true,
  },
})

const container: Ref<HTMLElement | null> = ref(null)

const emit = defineEmits(['update:modelValue'])
const slots = useSlots()

const toggleChange = (event) => {
  container.value.classList.toggle('label', event.target?.value && props.hasLabel && 0 === Object.keys(slots).length)
  emit('update:modelValue', event.target?.value)
}

const selectInput = () => {
  container.value.querySelector('input').focus()
}

const computedLabel = ref(props.placeHolder)

onMounted(() => {
  if (props.required) {
    computedLabel.value = computedLabel.value + '*'
  }
})
</script>

<template>
  <div
    ref="container"
    class="form-input"
    :class="{ icon: Object.keys($slots).length !== 0, label: 0 !== props.modelValue.length && 0 === Object.keys($slots).length }"
    @click="selectInput"
  >
    <slot />
    <label
      v-if="0 === Object.keys($slots).length"
      :for="uniqueId"
    >
      {{ computedLabel }}
    </label>
    <input
      :id="uniqueId"
      :type="type"
      :placeholder="computedLabel"
      :value="modelValue"
      :name="name"
      :required="required"
      @input="toggleChange"
    >
  </div>
</template>

<style scoped lang="scss">
.form-input {
  position: relative;
  border: 1px solid var(--color-navy-150);

  label {
    display: none;
  }

  &.label {
    padding: 0.8rem 1rem;;

    input {
      padding: 0;
    }

    label {
      display: block;
      text-transform: uppercase;
    }
  }

  &.icon {
    input {
      padding: $space-sm $space-sm $space-sm $space-3xl;
    }

    :deep(svg) {
      font-size: 1.5em;
      position: absolute;
      top: 16px;
      left: 16px;
    }
  }

  input {
    width: 100%;
    font-size: 1rem;
    color: var(--color-grey-550);
    border: none;
    padding: 0.8rem 1rem;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: var(--color-grey-550);
      text-transform: uppercase;
      opacity: 1;
    }
  }
}
</style>
